import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faEye, faFile, faChartLine } from '@fortawesome/free-solid-svg-icons';

const iconMapper = {
    "faVideo": faVideo,
    "faEye": faEye,
    "faFile": faFile,
    "faChartLine": faChartLine
};

const getIcon = (iconName) => {
    const icon = iconMapper[iconName];
    if (icon) {
        return <FontAwesomeIcon icon={icon} size="2x" />;
    }
    return null;
};

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>How it Works</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                  {getIcon(d.icon)}
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
