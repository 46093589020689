import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHourglassHalf, faUtensils, faIdCard, faCar, faClock } from '@fortawesome/free-solid-svg-icons';


const iconMapper = {
    "faUsers": faUsers,
    "faHourglassHalf": faHourglassHalf,
    "faUtensils": faUtensils,
    "faIdCard": faIdCard,
    "faCar": faCar,
    "faClock": faClock
};

const getIcon = (iconName) => {
  const icon = iconMapper[iconName];
  if (icon) {
      return <FontAwesomeIcon icon={icon} size="2x" />; // You can change '2x' to '3x', '4x', etc. to adjust the size.
  }
  return null;
};


export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Features to Choose From</h2>
          <p>
            From data collection to delivering actionable insights, our services are designed to harness the power of advanced analytics for your restaurant.
            Explore our offerings to see how we can help you transform your operations and accelerate growth.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {getIcon(d.icon)}
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
